$(function(){
    initMobileMenu();
    initAdsBanner();

    if ($('#popUpsContainer').length) {
        setTimeout(function () {
            var items = [];
            $('#popUpsContainer').find('.uno-mfp-popup').each(function () {
                items.push({
                    src: $(this)
                });
            });
            $.magnificPopup.open({
                items: items,
                type: 'inline',
                gallery: {
                    enabled: true
                }
            });
        }, 5000);
    }

    $('.btnCloseMagnific').click(function(){
        $.magnificPopup.close();
    });

    initSearchBar();

    function initSearchBar() {
        $('.search').click(function(){
            var $searchBar = $('#searchBar');
            if($(this).data('opened') != 1) {
                $(this).data('opened', 1);
                $(this).addClass('active');
                $(this).find('.fa').removeClass('fa-search').addClass('fa-times');
                $searchBar.fadeIn();
                $searchBar.find('input[type="text"]').focus();
            }else{
                $(this).data('opened', 0);
                $(this).removeClass('active');
                $(this).find('.fa').removeClass('fa-times').addClass('fa-search');
                $searchBar.fadeOut();
            }
        });
    }

    // var headerheight = $("#header-content").outerHeight();
    // $(".search-advisor-body").css("top", headerheight);


    $(".box").matchHeight();

    var homeNews = $(".home-news-item-text");
    sameHeight(homeNews);

    var backTop = $(".back-to-top");
    backTop.click(function () {
        $('body, html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    $(".studylevel-preu").on("click", function() {
        // var preu = $(this).data('preu');
        // var url = window.location.href;
        // var stateObj = { Title : "New title", Url:url + "&preu=" + preu};
        // console.log(stateObj);
        // var urlSplit = stateObj.Url.split( "?" );
        // var urlSplitAfter = urlSplit[1].split("&");
        // console.log(urlSplitAfter);
        // history.pushState(stateObj, stateObj.Title, stateObj.Url);

        // $(".course-undergraduate").css("display", "none");
        // $(".course-postgraduate").css("display", "none");
        // $(".studylevel").removeClass("clicked");
        // $(this).addClass("clicked");
        // $("#inner").css("padding-bottom", "0");
        // $(".course-preu").fadeIn(500);
        // $('html,body').animate({
        //     scrollTop: $(".course-preu").offset().top},
        // 'slow');

        var theUrl = $(this).data('href');
        window.location.href = theUrl + '&preu=1';
        //alert(window.location.host);
        //alert(window.location.pathname + '/areastudy/courses?areastudy=1&preu=1');
    });

    $(".studylevel-undergrad").on("click", function() {
        // $(".course-preu").css("display", "none");
        // $(".course-postgraduate").css("display", "none");
        // $(".studylevel").removeClass("clicked");
        // $(this).addClass("clicked");
        // $("#inner").css("padding-bottom", "0");
        // $(".course-undergraduate").fadeIn(500);
        // $('html,body').animate({
        //     scrollTop: $(".course-undergraduate").offset().top},
        // 'slow');

        var theUrl = $(this).data('href');
        window.location.href = theUrl + '&undergrad=1';
        //window.location.href = window.location.href + '&undergrad=1';
    });

    $(".studylevel-postgrad").on("click", function() {
        // $(".course-preu").css("display", "none");
        // $(".course-undergraduate").css("display", "none");
        // $(".studylevel").removeClass("clicked");
        // $(this).addClass("clicked");
        // $("#inner").css("padding-bottom", "0");
        // $(".course-postgraduate").fadeIn(500);
        // $('html,body').animate({
        //     scrollTop: $(".course-postgraduate").offset().top},
        // 'slow');

        var theUrl = $(this).data('href');
        window.location.href = theUrl + '&postgrad=1';
        //window.location.href = window.location.href + '&postgrad=1';

    });

    $(".course-details .course-details-header a").on("click", function() {
        var currentClass = $(this).parent().parent();
        var currentIcon = $(this).find(".course-icon-right");
        var tabvalue = $(this).find("input.tabvalue").val();
        var panel = $(".sidepanel-"+tabvalue);

        if (currentClass.hasClass("course-detail-toggle1")) {
            currentIcon.removeClass("fa-chevron-down");
            currentIcon.addClass("fa-chevron-up");
            currentClass.removeClass("course-detail-toggle1");
            currentClass.addClass("course-detail-toggle2");
            $('.course-sidepanel-item').removeClass("currentpanel");
            $('.course-sidepanel-item').addClass("closedpanel");
            panel.removeClass("closedpanel");
            panel.addClass("currentpanel");
            $('html,body').animate({
                scrollTop: currentClass.offset().top-$('header').height()},
            'slow');

        } else if (currentClass.hasClass("course-detail-toggle2")) {
            currentIcon.removeClass("fa-chevron-up");
            currentIcon.addClass("fa-chevron-down");
            currentClass.removeClass("course-detail-toggle2");
            currentClass.addClass("course-detail-toggle1");
            // panel.removeClass("currentpanel");
            // panel.addClass("closedpanel");
        }
    });

    $(".course-sidepanel-item").on("click", function() {
        var currenttab = "#"+$(this).find("a").attr("class");
        var currenttoggle = $(".course-details-header a[href='"+currenttab+"']");
        var currentClass = currenttoggle.parent().parent();
        var currentLink = currentClass.find("a");
        var currentIcon = currenttoggle.find(".course-icon-right");

        if (currentClass.hasClass("course-detail-toggle1")) {
            currentIcon.removeClass("fa-chevron-down");
            currentIcon.addClass("fa-chevron-up");
            // currentClass.removeClass("course-detail-toggle1");
            // currentClass.addClass("course-detail-toggle2");
            currentLink.click();

            $(".course-sidepanel-item").removeClass("currentpanel");
            $(".course-sidepanel-item").addClass("closedpanel");
            $(this).removeClass("closedpanel");
            $(this).addClass("currentpanel");
            $('html,body').animate({
                scrollTop: currentClass.offset().top-$('header').height()},
            'slow');

        } else if (currentClass.hasClass("course-detail-toggle2")) {
            $(".course-sidepanel-item").removeClass("currentpanel");
            $(".course-sidepanel-item").addClass("closedpanel");
            $(this).removeClass("closedpanel");
            $(this).addClass("currentpanel");
            $('html,body').animate({
                scrollTop: currentClass.offset().top-$('header').height()},
            'slow');
        }

    });

    $('.form-builder-iframe').on('load', function(){
        var $this = this;

        setTimeout(function() {
            $this.style.height = $this.contentDocument.body.scrollHeight +'px';
        }, 200);

        $(this).contents().on('click', function () {
            setTimeout(function() {
                var newHeight = $this.contentDocument.body.scrollHeight +'px';

                if (newHeight !== $this.style.height) {
                    $this.style.height = newHeight;
                }
            }, 200);
        })
    });
});

function initMobileMenu() {
    var menu = $('#menu nav').clone(),
        mobileMenu = $('#mobile-menu');
    mobileMenu.append(menu.html()).mmenu({
        offCanvas: {
            position: "right",
            zposition: "front"
        }
    });
}

function initAdsBanner(){
    var banner = $('.home-whyapu-list'), slidesClass = '.slide',
        slickSetting = {
            infinite: true,
            centerMode: true,
            centerPadding: '60px',
            slidesToShow: 3,
            dots: false,
            arrow: true,
            slide: slidesClass,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
    banner.on('init', function(e,el){
        banner.css({'opacity':'1'});
        $('#menu').css({'visibility': 'visible'});
    }).slick(slickSetting);
    $('.imgbg').hide();
}
$(window).resize(function(){
    // $('#breadcrumb').css({
    //     'margin-top': -$('#breadcrumb').innerHeight()
    // })
})
$(window).load(function() {
    imgToBG($('.bgimg'), '.imgbg');
    // $('#breadcrumb').css({
    //     'margin-top': -$('#breadcrumb').innerHeight()
    // })
});
$('div.panel-collapse.collapse').removeClass('in');

$('div.panel-collapse.collapse').on('shown.bs.collapse', function (e) {
    $(e.target).prev('.panel-heading').addClass('panel-heading-opened');
    var offset = $(this).offset().top - 155;
    $('html, body').animate({scrollTop:offset}, 'slow');
})
$('div.panel-collapse.collapse').on('hidden.bs.collapse', function (e) {
    $(e.target).prev('.panel-heading').removeClass('panel-heading-opened');
})

function imgToBG(ele, _imgClass){
    if(ele.length > 0){
        ele.each(function(){
            var _this = $(this), img = _this.find(_imgClass).eq(0) || _this.find('img').eq(0);
            _this.css({'background': 'url("'+img.attr('src')+'")', 'background-repeat':'no-repeat'});
            img.hide();
        });
    }
}
function sameHeight($element) {
    var elementHeights = $element.map(function() {
        return $(this).height();
    }).get();

    // Math.max takes a variable number of arguments
    // `apply` is equivalent to passing each height as an argument
    var maxHeight = Math.max.apply(null, elementHeights);

    // Set each height to the max height
    $element.height(maxHeight);
}